<template>
  <div>
    <div class="b-home p-3">
      <div class="bd-user">
        <div class="bd-user-image d-flex justify-content-center w-100">
          <img :src="userData.foto" alt="" class="img-thumbnail" />
        </div>
        <div
          class="bd-user-info d-flex flex-column align-items-lg-center justify-content-center text-center p-3 text-dark pt-5 bg-light rounded-3"
        >
          <h1>{{ userData.fullname }}</h1>
          <h4>{{ userData.status_karyawan }}</h4>
          <h4 v-show="userData.email">{{ userData.email }}</h4>
          <h4>{{ userData.no_hp }}</h4>
        </div>
      </div>
    </div>
    <div class="b-profile p-3">
      <b-list-group>
        <b-list-group-item>
          <router-link to="/profile/update">
            <b-icon icon="person" class="me-2" />
            Ubah Data Pribadi
          </router-link>
        </b-list-group-item>
        <b-list-group-item>
          <router-link to="/profile/update-password">
            <b-icon icon="person" class="me-2" />
            Update Password
          </router-link>
        </b-list-group-item>
        <!-- <b-list-group-item>
        <router-link to="/faq">
          <b-icon icon="chat" class="me-2" />
          Faq
        </router-link>
      </b-list-group-item> -->
        <!-- <b-list-group-item>
        <router-link to="/camera">
          <b-icon icon="camera" class="me-2" />
          Camera
        </router-link>
      </b-list-group-item> -->
        <b-list-group-item>
          <a @click="logout()" href="#">
            <b-icon icon="box-arrow-left" class="me-2" />
            Logout
          </a>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      userData: {
        fullname: null,
        foto: null,
        status_karyawan: null,
        email: null,
        no_hp: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    user(val) {
      let user = val;
      if (user && user.token && user.data.user_id) {
        this.$router.push("/");
      } else {
        window.location.href = "/login";
      }
    },
  },
  methods: {
    ...mapActions(["logout"]),
    getUserData() {
      if (this.user.data) {
        this.userData.fullname = this.user.data.fullname;
        this.userData.foto = this.user.data.foto;
        this.userData.status_karyawan = this.user.data.status_karyawan;
        this.userData.email = this.user.data.email;
        this.userData.no_hp = this.user.data.no_hp;
      }
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>
